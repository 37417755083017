<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt-5">
      <div class="alert alert-success" v-if="postStatus === 'success'">Promocja została zaktualizowana</div>
      <div class="alert alert-success" v-if="postStatus === 'error'">Wystąpił błąd</div>
      <form action="" @submit.prevent="handleCreateTimeDiscount">
        <div class="row">
          <div class="col-md-6">
            <card>
              <div slot="header">
                <h3 class="mb-0">Promocja czasowa</h3>
              </div>
              <base-input label="Nazwa promocji" v-model="name" />
              <base-input label="Opis promocji" v-model="descriptionShort" />
              <div class="row">
                <div class="col-md-6">
                  <base-input label="Wysokość promocji" v-model="discount" />
                </div>
                <div class="col-md-6">
                  <base-input label="Promocja dla kategorii">
                    <el-select v-model="categories"
                               multiple
                               filterable
                               placeholder="Wybierz kategorie...">
                      <el-option v-for="option in categoryList"
                                 :key="option.id"
                                 :label="option.name"
                                 :value="option.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-6">
                  <base-input label="Promocja dla linii">
                    <el-select v-model="lines"
                               multiple
                               filterable
                               placeholder="Wybierz linie...">
                      <el-option v-for="option in lineList"
                                 :key="option.id"
                                 :label="option.name"
                                 :value="option.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-6">
                  <base-input label="Promocja dla tagu">
                    <el-select v-model="tags"
                               multiple
                               filterable
                               placeholder="Wybierz tagi...">
                      <el-option v-for="option in tagList"
                                 :key="option.id"
                                 :label="option.name"
                                 :value="option.id">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-6">
            <card>
              <div slot="header">
                <h3 class="mb-0">Dodatkowe parametry</h3>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5>Czy darmowa dostawa?</h5>
                  <base-switch
                    on-text="Tak"
                    off-text="Nie"
                    v-model="freeShipping"
                  ></base-switch>
                </div>
                <div class="col-md-6 mb-3">
                  <h5>Czy aktywny?</h5>
                  <base-switch
                    on-text="Tak"
                    off-text="Nie"
                    v-model="active"
                  ></base-switch>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-control-label">Data od</label>
                    <DatePickerComponent v-model="dateFrom" class="d-block w-auto img-fluid mb-3" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-control-label">Data do</label>
                    <DatePickerComponent v-model="dateTo" class="d-block w-auto img-fluid mb-3" />
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-primary" type="submit" role="button">
            Zapisz zmiany
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import DatePickerComponent from "@/components/Datepicker";
import {Option, Select} from "element-ui";
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'ProductTimeDiscountView',
  components: {
    BreadcrumbHeader,
    DatePickerComponent,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapState(
      {
        postStatus: state => state.PromoManage.postStatus,
        activeTags: state => state.PromoManage.data.tags,
        activeLines: state => state.PromoManage.data.lines,
        activeCategories: state => state.PromoManage.data.categories,
        _categories: state => state.ProductCategoryListModule.items,
        _lines: state => state.ProductLineListModule.items,
        _tags: state => state.ProductTagListModule.items
      }),
    ...mapState('PromoManage', ['data']),
    name: {
      get() {
        return this.$store.state.PromoManage.data.name
      },
      set(newVal) {
        this.updateField({ field: 'name', value: newVal })
      }
    },
    description() {
      return this.$store.state.PromoManage.data.description ?? {}
    },
    descriptionShort: {
      get() {
        return this.$store.state.PromoManage.data.description?.short ?? ''
      },
      set(newVal) {
        this.updateField({ field: 'description', value: {
          ...this.description,
          short: newVal
          } })
      }
    },
    discount: {
      get() {
        return this.$store.state.PromoManage.data.discount / 100
      },
      set(newVal) {
        this.updateField({ field: 'discount', value: newVal * 100 })
      }
    },
    active: {
      get() {
        return this.$store.state.PromoManage.data.active
      },
      set(newVal) {
        this.updateField({ field: 'active', value: newVal })
      }
    },
    freeShipping: {
      get() {
        return this.$store.state.PromoManage.data.freeShipping
      },
      set(newVal) {
        this.updateField({ field: 'freeShipping', value: newVal })
      }
    },
    dateFrom: {
      get() {
        return this.$store.state.PromoManage.data.dateFrom
      },
      set(newVal) {
        this.updateField({ field: 'dateFrom', value: newVal })
      }
    },
    dateTo: {
      get() {
        return this.$store.state.PromoManage.data.dateTo
      },
      set(newVal) {
        this.updateField({ field: 'dateTo', value: newVal })
      }
    },
    tags: {
      get() {
        return this.activeTags
      },
      set(newVal) {
        this.updateField({ field: 'tags', value: newVal })
      }
    },
    lines: {
      get() {
        return this.activeLines
      },
      set(newVal) {
        this.updateField({ field: 'lines', value: newVal })
      }
    },
    categories: {
      get() {
        return this.activeCategories
      },
      set(newVal) {
        this.updateField({ field: 'categories', value: newVal })
      }
    },
    categoryList() {
      return this._categories.map( item => ({ name: item.name, id: item.id }))
    },
    tagList() {
      return this._tags.map( item => ({ name: item.name, id: item.id }))
    },
    lineList() {
      return this._lines.map( item => ({ name: item.name, id: item.id }))
    },
    breadcrumb() {
      return [
        {title: 'Administracja', link: {name: 'administration'}},
        {title: 'Promocje czasowe', link: {name: 'product-time-discount-list'}},
        {title: 'Nowa promocja'}
      ];
    },
  },
  methods: {
    ...mapMutations('PromoManage', ['updateField']),
    ...mapActions('PromoManage', ['submitTimeDiscount', 'mountedHook']),
    async handleCreateTimeDiscount() {
      const resp = await this.submitTimeDiscount(this.$route.params.id);
    }
  },
  async beforeMount() {
    await this.mountedHook(this.$route.params.id)
  }
};
</script>
